import React, { useState, useEffect, useRef } from "react"
import { useLocation } from '@reach/router';
import { graphql } from "gatsby"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import HeadlineBlock from "../components/blocks/HeadlineBlock"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import SEO from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Seo"
import { Helmet } from 'react-helmet';

import * as moment from "moment"
import Masonry from "react-masonry-css"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowAltRight } from "@fortawesome/pro-solid-svg-icons"

function getQuery(page, category) {
  const query = [
    ...(page > 1 ? [`page=${page}`] : []),
    ...(category !== "all" ? [`category=${encodeURIComponent(category)}`] : []),
  ].join('&');
  (query.length > 0 ? '?' + query : '');
  return query != '' ? '?' + query : '';
}

const Magazin = ({ data }) => {
  const { pageData, entries } = data
  const pageContent = JSON.parse(pageData.content)
  const NEWS_LIMIT = 12;
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const canonical_url = 'https://morgenundmorgen.com/magazin/' + getQuery(page, selectedCategory);

  const allNews = entries.nodes.map(
    (node) => {
      if (!node.content) return node;
      const content = JSON.parse(node.content);
      return { ...node, content };
    }
  ).filter((node) => node.content.headline?.length);
  const filteredNews = allNews.filter((node) => selectedCategory === 'all' || node.tag_list.includes(selectedCategory));
  const newsList = [...filteredNews.slice(0, NEWS_LIMIT * page)];
  const hasMore = newsList.length < filteredNews.length;
  const containerRef = useRef(null)

  // Get all categories
  let tagList = []
  allNews.forEach((node) => node.tag_list.forEach((tag) => tagList.push(tag)));
  tagList.sort();
  const uniqueCategories = new Set(tagList);
  const categories = [...uniqueCategories];

  const updateHistory = (page, category) => {

    window.history.pushState({}, '', window.location.pathname + getQuery(page, category));
  }

  const selectCategory = (e) => {
    const category = e.target.value;
    setSelectedCategory(category);
    setPage(1);
    updateHistory(1, category);
  }

  const loadMore = () => {
    setPage(page + 1);
    updateHistory((page + 1), selectedCategory);
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const newPage = parseInt(queryParams.get('page'));
    const newSelectedCategory = queryParams.get('category') || 'all';
    setPage(isNaN(newPage) || newPage < 1 ? 1 : newPage);
    setSelectedCategory(newSelectedCategory);
  }, [location]);

  useEffect(() => {
    if (page > 1) {
      // Scroll to the last element of the previous page
      const lastElement = document.getElementById('listElement_' + ((page-1) * NEWS_LIMIT));
      if (lastElement) {
        lastElement.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [page, selectedCategory]);

  const breakpoints = {
    default: 3,
    1024: 2,
    768: 1,
  }

  return (
    <div>
      <Helmet
        meta={[
          {
            name: `robots`,
            content: `index, follow`
          },
        ]}
        link={[
          {
            rel: `canonical`,
            href: `${canonical_url}`
          },
        ]}
      />
      <Layout metadata={pageContent.metadata}>
        <SEO content={pageContent} />
        {/* <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs> */}
        <HeadlineBlock
          block={{
            headline_level: "h1",
            kicker: pageContent.kicker,
            headline: pageContent.headline,
            subheadline: pageContent.subheadline,
          }}
        />
        <BlockWrapper block={{ block_width: "" }}>
          <select
            className="px-4 py-2 border border-secondary text-secondary"
            value={selectedCategory}
            onChange={selectCategory}
          >
            <option value={"all"}>Alle Kategorien</option>
            {categories.map((option, index) => (
                <option value={option} key={index}>
                  {option}
                </option>
              )
            )}
          </select>
          <div className="mt-8 mx-grid" ref={containerRef}>
            <Masonry
              breakpointCols={breakpoints}
              className="masonry-grid"
              columnClassName="masonry-grid_column"
            >
              {newsList.map((item, index) => {
                const content = item.content;
                return (
                  <div className="mb-8" key={index} id={'listElement_' + index}>
                    <Link
                      className={`block text-white transition-all duration-200 ease-in transform  ${
                        content.card_style
                          ? `bg-${content.card_style}`
                          : `bg-blue`
                      } hover:scale-105 hover:shadow-lg`}
                      link={`/${item.full_slug}`}
                    >
                      <div className="p-8">
                        {content.kicker?.length > 0 && (
                          <span
                            className={`inline-block px-3 py-1 font-bold uppercase bg-white ${
                              content.card_style
                                ? `text-${content.card_style}`
                                : `text-blue`
                            } lg:text-lg font-display`}
                          >
                            {content.kicker}
                          </span>
                        )}
                        {content.headline?.length > 0 && (
                          <span className="block mt-2 text-2xl font-black hyphens-auto lg:text-3xl font-display">
                            {content.headline}
                          </span>
                        )}
                        {content.subheadline?.length > 0 && (
                          <span className="block mt-4 font-bold">
                            {content.subheadline}
                          </span>
                        )}
                        {item.first_published_at !== null && (
                          <span className="inline-block mt-2 text-sm">
                            <FontAwesomeIcon
                              icon={faLongArrowAltRight}
                              size="2x"
                              className="mt-1 mr-2 -mb-1"
                            />

                            <span className="inline-block text-base font-bold">
                              {moment(item.first_published_at).format(
                                "DD.MM.YYYY"
                              )}
                            </span>
                          </span>
                        )}
                      </div>
                    </Link>
                  </div>
                )
              })}
            </Masonry>
          </div>
          {hasMore ? (
            <div className="text-center mt-9">
              <a className="btn btn-primary" href={'/magazin' + getQuery(page + 1, selectedCategory) + "&x=1"} onClick={(e) => { e.preventDefault();loadMore(); }}>
                Mehr anzeigen
              </a>
            </div>
          ) : null}
          <BodyBlocks body={pageContent.body} />
        </BlockWrapper>
      </Layout>
    </div>
  )
}

export const data = graphql`
  {
    pageData: storyblokEntry(slug: { eq: "magazin" }) {
      name
      slug
      content
      full_slug
    }
    entries: allStoryblokEntry(
      filter: { field_component: { eq: "news_page" } }
      sort: { fields: first_published_at, order: DESC }
    ) {
      nodes {
        full_slug
        name
        content
        tag_list
        field_component
        first_published_at
      }
    }
  }
`

export default Magazin
